import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from 'gatsby-source-datocms';
import sanitizeHtml from 'sanitize-html';

import "./voorwaarden.css";

export default function Template( {
  data, // this prop will be injected by the GraphQL query below.
} ) {
  const sanitizer = ( html ) => {
    return sanitizeHtml( html );
  };

  const markdownData  = data.datoCmsVoorwaarden;
  const html  = markdownData.vwInhoudNode.childMarkdownRemark.html;
  return (
    <div className="markdown-container">
      <HelmetDatoCms seo={ markdownData.seoMetaTags } />
      <div className="markdown">
        <h1>{ markdownData.vwTitel }</h1>
        <span id="markdown-info">
          <p>Versie: { markdownData.vwVersieDatum }</p>
          <p>Dit document kan u ook 
            <a 
              href={ markdownData.vwBijlage.url } 
              download 
              target="_blank"
              rel="noopener noreferrer"
            >
              {` `}
              downloaden
            </a>
          </p>
        </span>
        <div
          className="markdown-content"
          dangerouslySetInnerHTML={ { __html: sanitizer( html ) } }
        />
      </div>
    </div>
  )
};

export const pageQuery = graphql`
  query Voorwaarden( $slug: String! ) {
    datoCmsVoorwaarden( vwSlug: { eq: $slug } ) {
      seoMetaTags {
        tags
      }
      vwTitel
      vwVersieDatum
      vwInhoudNode {
        childMarkdownRemark {
          html
        }
      }
      vwBijlage {
        url
      }
    }
  }  
`